const mockRecognition = {
  isAvailable: true,
  start: () => console.log('Mock recognition started'),
  stop: () => console.log('Mock recognition stopped'),
  onresult: null,
  continuous: true,
  interimResults: true,
  lang: 'zh-CN'
};

class SpeechRecognitionService {
  constructor(useMock = false) {
    this.recognition = useMock ? mockRecognition :
      new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    
    this.recognition.continuous = true;
    this.recognition.interimResults = true;
    this.recognition.lang = 'zh-CN';
  }

  start() {
    this.recognition.start();
  }

  stop() {
    this.recognition.stop();
  }

  setResultHandler(callback) {
    this.recognition.onresult = (event) => {
      const current = event.resultIndex;
      const transcript = event.results[current][0].transcript;
      const isFinal = event.results[current].isFinal;
      
      callback(transcript, isFinal);
    };
  }
}

export default SpeechRecognitionService;

