const mockSpeech = {
  speak: (text) => console.log('Mock speaking:', text)
};

class SpeechSynthesisService {
  constructor(useMock = false) {
    this.useMock = useMock;
  }

  speak(text) {
    if (this.useMock) {
      mockSpeech.speak(text);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'zh-CN';
    window.speechSynthesis.speak(utterance);
  }
}

export default SpeechSynthesisService;

