const mockResponses = [
  "这是一个模拟的回复。",
  "我是测试用的AI助手。",
  "您好，我可以帮您解答问题。"
];

class LLMService {
  constructor(useMock = false) {
    this.useMock = useMock;
    this.apiEndpoint = process.env.REACT_APP_LLM_API_ENDPOINT;
    this.apiKey = process.env.REACT_APP_LLM_API_KEY;
  }

  async getResponse(text) {
    if (this.useMock) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const randomResponse = mockResponses[Math.floor(Math.random() * mockResponses.length)];
          resolve(randomResponse);
        }, 500);
      });
    }

    const response = await fetch(this.apiEndpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.apiKey}`
      },
      body: JSON.stringify({
        prompt: `用户说: ${text}\n请回复:`,
        max_tokens: 150
      })
    });

    const data = await response.json();
    return data.choices[0].text;
  }
}

export default LLMService;

