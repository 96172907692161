import { useState, useEffect } from 'react';
import './App.css';
import SpeechRecognitionService from './services/speechRecognition';
import LLMService from './services/llmService';
import SpeechSynthesisService from './services/speechSynthesis';

// 是否使用模拟数据
const USE_MOCK = true;

function App() {
  const [isListening, setIsListening] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [response, setResponse] = useState('');

  const speechRecognition = new SpeechRecognitionService(USE_MOCK);
  const llmService = new LLMService(USE_MOCK);
  const speechSynthesis = new SpeechSynthesisService(USE_MOCK);

  useEffect(() => {
    speechRecognition.setResultHandler(async (text, isFinal) => {
      setTranscript(text);
      
      if (isFinal) {
        try {
          const llmResponse = await llmService.getResponse(text);
          setResponse(llmResponse);
          speechSynthesis.speak(llmResponse);
        } catch (error) {
          console.error('Error:', error);
        }
      }
    });
  }, []);

  const toggleListening = () => {
    if (isListening) {
      speechRecognition.stop();
    } else {
      speechRecognition.start();
    }
    setIsListening(!isListening);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>语音助手 {USE_MOCK ? '(测试模式)' : ''}</h1>
        <button 
          onClick={toggleListening}
          className={`listen-button ${isListening ? 'listening' : ''}`}
        >
          {isListening ? '停止听取' : '开始对话'}
        </button>
        <div className="transcript">
          <h3>你说:</h3>
          <p>{transcript}</p>
        </div>
        <div className="response">
          <h3>助手回复:</h3>
          <p>{response}</p>
        </div>
      </header>
    </div>
  );
}

export default App;
